<template>
  <v-container fluid>
    <v-alert
      v-if="pedido.idPedidoAssociado"
      color="#2A3B4D"
      dark
      icon="mdi-firework"
      dense
      class="d-print-none"
    >
      <strong>O PEDIDO
        <a
          class="text-decoration-none"
          @click="toPedidoAssociado"
        >
          <strong>#{{ pedido.idPedidoAssociado }}</strong>
        </a> DEVE SER ENVIADO JUNTAMENTE COM ESTE PEDIDO.
      </strong>
    </v-alert>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-card>
          <v-container>
            <v-row justify="space-between">
              <v-col cols="12">
                <h1 class="d-print-none">
                  Pedido
                </h1>
                <img
                  class="d-none d-print-block"
                  src="@/assets/logo.png"
                  style="max-width: 90px"
                >
              </v-col>
              <v-col cols="6">
                <ul class="nopadding">
                  <li><strong>Pedido: </strong>{{ pedido.id }}</li>
                  <li>
                    <strong>Revendedor(a): </strong>{{
                      pedido.cliente.codigoStyllus +
                        " - " +
                        pedido.cliente.nome
                    }}
                  </li>
                  <li>
                    <strong>Tipo: </strong>{{
                      pedido.cliente.tipo
                    }}
                  </li>
                  <div class="d-print-none">
                    <li>
                      <strong>Status do Pedido: </strong>{{
                        pedido.status
                          ? pedido.status.nomeStatus
                          : ""
                      }}
                    </li>
                    <div v-if="pedido.transactionId">
                      <li>
                        <strong>Tipo de Pagamento: </strong>{{ pedido.formaPagamento }}
                        <span v-if="pedido.urlPagamento">
                          -
                          <a
                            :href="pedido.urlPagamento"
                            target="_blank"
                          >Baixar</a></span>
                      </li>
                      <li>
                        <strong>Status de Pagamento: </strong>
                        {{ pedido.statusPagamentoTexto }}
                      </li>
                    </div>
                  </div>
                  <li v-if="pedido.tipoFrete">
                    <strong>Tipo de envio: </strong>{{ pedido.tipoFrete.nome }}
                  </li>
                  <li v-if="pedido.centroDistribuicao">
                    <strong>CD: </strong>{{ pedido.centroDistribuicao.nome }} -
                    {{ pedido.centroDistribuicao.logradouro }} -
                    {{ pedido.centroDistribuicao.numero }} -
                    {{ pedido.centroDistribuicao.complemento }} -
                    {{ pedido.centroDistribuicao.bairro }}
                  </li>
                </ul>
              </v-col>
              <v-col
                cols="6"
                class="d-print-none"
              >
                <v-row>
                  <v-col
                    v-if="pedido.nFe"
                    cols="12"
                  >
                    <v-card>
                      <v-card-text>
                        <h3>Nota Fiscal</h3>
                        <ul class="nopadding">
                          <li>
                            Número:
                            <strong>{{ pedido.nFe.notafiscal.numero }}</strong>
                          </li>
                          <li>
                            Série:
                            <strong>{{ pedido.nFe.notafiscal.serie }}</strong>
                          </li>
                          <li v-if="pedido.nFe.notafiscal.linkDanfe">
                            DANFE:
                            <a
                              :href="pedido.nFe.notafiscal.linkDanfe"
                              target="_blank"
                            >Baixar</a>
                          </li>
                        </ul>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <h3>Itens</h3>
                <table>
                  <thead>
                    <tr>
                      <th>Descrição</th>
                      <th>Cat.</th>
                      <th>Ref.</th>
                      <th>Tam.</th>
                      <th>Cor</th>
                      <th>Qtd.</th>
                      <th class="d-print-none">
                        Preço Bruto
                      </th>
                      <th class="d-print-none">
                        Desconto
                      </th>
                      <th>
                        <span class="d-inline d-print-none">Preço Liquido</span>
                        <span class="d-none d-print-inline">Preço</span>
                      </th>
                      <th class="d-print-none">
                        Total Bruto
                      </th>
                      <th>
                        <span class="d-inline d-print-none">Total Líquido</span>
                        <span class="d-none d-print-inline">Total</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="prod in itensDoPedido"
                      :key="prod.statusPedidoId"
                      :class="[ prod.grupoItemPedido ? 'classeGrupo' : 'classeSemGrupo' ]"
                    >
                      <td
                        v-if="prod.grupoItemPedido"
                        scope="row"
                        data-title="Produto"
                      >
                        <small>{{ prod.grupoItemPedido.produto.nome }}</small>
                        <p>{{ prod.nome }}</p>
                      </td>
                      <td
                        v-else
                        scope="row"
                        data-title="Produto"
                      >
                        <span>{{ prod.nome }}</span>
                      </td>
                      <td
                        scope="row"
                        data-title="Catalogo"
                      >
                        {{ prod.catalogo }}
                      </td>
                      <td
                        scope="row"
                        data-title="Referência"
                      >
                        {{ prod.referencia }}
                      </td>
                      <td data-title="Tamanho">
                        {{ prod.tamanho }}
                      </td>
                      <td>
                        {{ prod.cor }}
                      </td>
                      <td>
                        {{ prod.quantidade }}
                      </td>
                      <td
                        class="price"
                        data-title="Preço Bruto"
                      >
                        {{ formater.format(prod.valor) }}
                      </td>
                      <td
                        class="price d-print-none"
                        data-title="Desconto"
                      >
                        {{ prod.desconto }}%
                      </td>
                      <td
                        class="price d-print-none"
                        data-title="Preço Líquido"
                      >
                        {{ formater.format(prod.valorLiquido) }}
                      </td>
                      <td
                        class="total"
                        data-title="Total Bruto"
                      >
                        {{ formater.format(prod.totalBruto) }}
                      </td>
                      <td
                        class="total d-print-none"
                        data-title="Total Liquido"
                      >
                        {{ formater.format(prod.totalLiquido) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
              <v-col cols="12">
                <v-card class="d-print-none">
                  <v-row>
                    <v-col cols="2">
                      <ul class="totais">
                        <li>
                          Frete
                          <span
                            v-if="pedido.tipoFrete"
                          >- {{ pedido.tipoFrete.nome }}
                          </span>
                        </li>
                        <li>
                          <strong>R$
                            {{
                              pedido.frete ? pedido.frete.toFixed(2) : "0.00"
                            }}</strong>
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="2">
                      <ul class="totais">
                        <li>Quantidade :</li>
                        <li>
                          <strong>{{ quantidadeTotalItems }}</strong>
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="2">
                      <ul
                        v-show="pedido.descontoEmCashback > 0"
                        class="totais"
                      >
                        <li>Cashback Utilizado :</li>
                        <li>
                          <strong>R$
                            {{
                              pedido.descontoEmCashback
                                ? pedido.descontoEmCashback.toFixed(2)
                                : "0.00"
                            }}</strong>
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="1">
                      <ul class="totais">
                        <li>Desconto :</li>
                        <li>
                          <strong>R$
                            {{
                              pedido.desconto ? pedido.desconto.toFixed(2) : ""
                            }}</strong>
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="1">
                      <ul class="totais">
                        <li>Subtotal:</li>
                        <li>
                          <strong>R$
                            {{
                              pedido.valorSemDesconto
                                ? pedido.valorSemDesconto.toFixed(2)
                                : ""
                            }}</strong>
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="1">
                      <ul class="totais">
                        <li>Total Pago :</li>
                        <li>
                          <strong>R$ {{
                            pedido.totalLiquido
                              ? pedido.totalLiquido.toFixed(2)
                              : "0.00"
                          }}</strong>
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                </v-card>
                <v-card class="d-none d-print-block">
                  <v-row>
                    <v-col cols="2">
                      <ul class="totais">
                        <li>Quantidade :</li>
                        <li>
                          <strong>{{ quantidadeTotalItems }}</strong>
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="3">
                      <ul
                        v-show="pedido.descontoEmCashback > 0"
                        class="totais"
                      >
                        <li>Cashback Utilizado :</li>
                        <li>
                          <strong>R$
                            {{
                              pedido.descontoEmCashback
                                ? pedido.descontoEmCashback.toFixed(2)
                                : "0.00"
                            }}</strong>
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="2">
                      <ul class="totais">
                        <li>Desconto :</li>
                        <li>
                          <strong>R$
                            {{
                              pedido.desconto ? pedido.desconto.toFixed(2) : ""
                            }}</strong>
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="2">
                      <ul class="totais">
                        <li>Subtotal:</li>
                        <li>
                          <strong>R$
                            {{
                              pedido.valorSemDesconto
                                ? pedido.valorSemDesconto.toFixed(2)
                                : ""
                            }}</strong>
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="3">
                      <ul class="totais">
                        <li>Total Pago :</li>
                        <li>
                          <strong>R$ {{
                            pedido.totalLiquido
                              ? pedido.totalLiquido.toFixed(2)
                              : "0.00"
                          }}</strong>
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                class="d-print-none"
              >
                <v-card>
                  <v-col cols="12">
                    <v-form lazy-validation>
                      <v-text-field
                        v-model="pedido.rastreio"
                        label="Código de Rastreio"
                        name="rastreio"
                        type="text"
                        :rules="rules"
                      />
                      <v-btn
                        color="primary"
                        class="mt-4"
                        @click="atualizarRastreio"
                      >
                        Salvar
                      </v-btn>
                    </v-form>
                  </v-col>
                </v-card>
              </v-col>
              <v-col cols="12 d-print-none">
                <h3>Alterações de status</h3>
                <v-data-table
                  :headers="[
                    { text: 'Status', sortable: false },
                    { text: 'Usuário', sortable: false },
                    { text: 'Data', sortable: false },
                    { text: 'Obs', sortable: false },
                  ]"
                  :items="pedido.historico"
                  class="elevation-1"
                  :hide-default-footer="true"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr
                        v-for="item in items"
                        :key="item.pedidoItemId"
                      >
                        <td>
                          {{ item.status.nomeStatus }}
                        </td>
                        <td>
                          {{ item.usuario.nome }}
                        </td>
                        <td>
                          {{ timestamp(item.dataOperacao) }}
                        </td>
                        <td>
                          {{ item.observacao }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-col>
              <v-col
                v-if="pedidosEmTransito.length > 0"
                cols="12"
              >
                <h3>Últimos pedidos em trânsito</h3>
                <v-data-table
                  :headers="[
                    { text: 'ID', sortable: false },
                    { text: 'Total', sortable: false },
                    { text: 'Data', sortable: false },
                    { text: 'Status do pedido', sortable: false },
                    { text: 'Status do pgto', sortable: false },
                  ]"
                  :items="pedidosEmTransito"
                  class="elevation-1"
                  :hide-default-footer="true"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr
                        v-for="item in items"
                        :key="item.id"
                      >
                        <td>#{{ item.id }}</td>
                        <td>
                          {{ formater.format(item.totalLiquido) }}
                        </td>
                        <td>
                          {{ timestamp(item.dataPedido) }}
                        </td>
                        <td>
                          {{ item.status.nomeStatus }}
                        </td>
                        <td>
                          {{ item.statusPagamentoTexto }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
          <v-divider />
          <v-card-actions class="acoes d-print-none">
            <v-btn
              v-if="!pedido.nFe"
              color="success"
              dar
              @click="enviarBling()"
            >
              GERAR NOTA
            </v-btn>
            <v-btn
              color="primary"
              dark
              @click="dialogAlterarStatus = true"
            >
              Alterar Estado
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogAlterarStatus"
      scrollable
      width="500"
    >
      <v-card>
        <v-card-title>Alteração do Estado do Pedido</v-card-title>
        <v-card-text>
          <v-select
            v-model="alteracao.novoStatus"
            label="Novo estado"
            :items="statuses"
            item-text="display"
            item-value="id"
          />
          <v-textarea
            v-model="alteracao.observacao"
            label="Observação"
            name="observacao"
            type="text"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions class="d-print-none">
          <v-btn
            color="red darken-1"
            text
            @click="dialogAlterarStatus = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="
              alterarEstado({
                StatusId: alteracao.novoStatus,
                PedidoId: pedido.id,
                Observacao: alteracao.observacao,
              })
            "
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import moment from 'moment'

  export default {
    name: 'Pedido',
    data: () => ({
      overlay: false,
      dialogAlterarStatus: false,
      pedido: {
        cliente: {
          pessoa: {},
          historico: [],
        },
        itens: [],
      },
      pedidoItens: [],
      statuses: [],
      pedidosEmTransito: [],
      alteracao: {},
      formater: new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
      rules: [(v) => !!v || 'Este campo é obrigatório'],
    }),
    computed: {
      quantidadeTotalItems () {
        return this.pedido.itens.reduce((a, b) => a + (b.quantidade || 0), 0)
      },
      itensDoPedido () {
        return this.ordenarPedidos()
      },
    },
    mounted () {
      if (this.$route.params.id) {
        this.load()
      }
    },
    methods: {
      load () {
        this.overlay = true
        this.$http.get('/pedido/status').then((resp) => {
          this.statuses = resp.data
        })
        this.$http.get(`/pedido/${this.$route.params.id}`).then((resp) => {
          this.pedido = resp.data
          const filter = {
            PerPage: 4,
            page: 1,
            Tipo: 1, // portal
            StatusId: 6, // confia em mim, status EM TRÂNSITO
            CodigoStyllus: this.pedido.cliente.styllusRevendedorId,
          }
          const query = JSON.stringify(filter)
          this.$http.get('/pedido', { params: { query } }).then((resp) => {
            this.pedidosEmTransito = resp.data.rows
            this.overlay = false
          })
        })
      },
      ordenarPedidos () {
        return this.pedido.itens.sort((a, b) => b.grupoItemPedidoId - a.grupoItemPedidoId)
      },
      timestamp (datestring) {
        return moment(datestring).format('DD/MM/YYYY HH:mm')
      },
      alterarEstado (payload) {
        this.$http.post('pedido/status', payload)
          .then(() => {
            this.dialogAlterarStatus = false
            this.$toast.success('Operação realizada com sucesso')
            this.load()
          })
          .catch(err => {
            try {
              const msg = err.response.data.message
              this.$toast.error(msg)
            } catch (error) {
              this.$toast.error('Falha ao realizar operação')
            }
          })
      },
      enviarBling () {
        this.$http
          .post(`pedido/${this.pedido.id}/nota-fiscal`)
          .then(() => {
            this.$toast.success('Operação realizada com sucesso')
            this.load()
          })
          .catch((err) => {
            try {
              const response = JSON.parse(err.response.data.message)
              const msg = Array.isArray(response) ? response[0].erro.msg : response.msg
              this.$toast.error(msg)
            } catch (error) {
              this.$toast.error('Falha ao realizar operação')
            }
          })
      },
      atualizarRastreio () {
        this.$http
          .put(`pedido/${this.pedido.id}/rastreio`, {
            id: this.pedido.id,
            rastreio: this.pedido.rastreio,
          })
          .then(() => {
            this.$toast.success('Operação realizada com sucesso')
          })
      },
      toPedidoAssociado () {
        this.$router.push(
          `/pages/pedidos/detail/${this.pedido.idPedidoAssociado}`,
        )
        window.location.reload()
      },
    },
  }
</script>

<style>
@page {
  size: auto; /* auto is the initial value */
  /* this affects the margin in the printer settings */
  margin: 5mm 5mm 5mm 5mm;
}
table {
  width: 100%;
  text-align: left;
}
.acoes {
  padding: 32px;
}

tr.totais td {
  font-weight: bolder;
}
tr {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
ul.nopadding {
  padding: 0;
}
ul li {
  list-style: none;
  white-space: nowrap;
  word-break: break-word;
}
.classeGrupo {
  background-color: #EAEAEA;
}
.classeGrupo > td {
  padding: 5px;
  margin: 0 0;
}
.classeGrupo > td > p {
  padding: 0;
  margin: 0;
}
.classeSemGrupo {
  background-color: #F3F3F3;
}
.classeSemGrupo > td {
  padding: 5px;
}
</style>
